// One of two remaining @import statements
// TODO: Investigate how to remove this import such that fonts work
@import '@vst/beam/styles/css/min/beam.min.css';

html {
  height: 100%;
  overflow: auto;
}

body {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
#root > div {
  width: 100%;
  /* Removed overflow hidden to enable sticky positioning in child elements*/
}

textarea:focus,
input:focus,
*:focus {
  outline: none !important;
}

[data-cy='feedbackButtonContent'] {
  -webkit-transform-origin: 50% 51%;
}

@mixin vectorIconsFont($fileName) {
  $vectorIconsPath: '../../../node_modules/react-native-vector-icons/Fonts/';
  $filePath: $vectorIconsPath + $fileName;

  @font-face {
    font-family: $fileName;
    src: url($filePath + '.ttf');
  }
}

@mixin andromedaFont($fontFamily, $fileName) {
  $andromedaPath: '../../../node_modules/@vst/beam/assets/fonts/';
  $filePath: $andromedaPath + $fileName;

  @font-face {
    font-family: $fontFamily;
    font-display: swap;
    src: url($filePath + '.woff2') format('woff2'),
      url($filePath + '.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
}

@include vectorIconsFont('MaterialCommunityIcons');

@include andromedaFont('UniNeueBook', 'uni-neue/unineue-book');
@include andromedaFont('UniNeueBold', 'uni-neue/unineue-bold');
@include andromedaFont('UniNeueHeavy', 'uni-neue/unineue-heavy');

@include andromedaFont(
  'SourceSansPro-Light',
  'source-sans/sourcesanspro-light'
);
@include andromedaFont(
  'SourceSansPro-LightItalic',
  'source-sans/sourcesanspro-lightitalic'
);
@include andromedaFont(
  'SourceSansPro-Regular',
  'source-sans/sourcesanspro-regular'
);
@include andromedaFont(
  'SourceSansPro-Italic',
  'source-sans/sourcesanspro-regularitalic'
);
@include andromedaFont(
  'SourceSansPro-SemiBold',
  'source-sans/sourcesanspro-semibold'
);
@include andromedaFont(
  'SourceSansPro-SemiBoldItalic',
  'source-sans/sourcesanspro-semibolditalic'
);
@include andromedaFont('SourceSansPro-Bold', 'source-sans/sourcesanspro-bold');
@include andromedaFont(
  'SourceSansPro-BoldItalic',
  'source-sans/sourcesanspro-bolditalic'
);
